/* tr.MuiTableRow-root.MuiTableRow-hover {

  tr:hover {
    background: gray !important;
  }
} */

tr.MuiTableRow-hover:hover {
  background: gray !important;
}
